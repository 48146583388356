import { dateFormats } from '@mindfulchefuk/lib/dateFormats'
import { addDays } from 'date-fns'

interface GetNextDateParams {
  fromDate: string
  unavailableDates: string[]
}

const getNextDate = ({
  fromDate,
  unavailableDates,
}: GetNextDateParams): string => {
  let currentDate: Date = fromDate ? new Date(fromDate) : new Date()
  let nextDate
  while (!nextDate) {
    const formattedDate = dateFormats.iso(currentDate)
    const isAvailable = !unavailableDates.includes(formattedDate)

    if (isAvailable) {
      nextDate = formattedDate
    } else {
      currentDate = addDays(currentDate, 1)
    }
  }

  return dateFormats.iso(nextDate)
}

export default getNextDate
