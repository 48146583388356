import { addDays, isAfter, isBefore } from 'date-fns'
import { DELIVERY_EDIT_GRACE_PERIOD } from '@mindfulchefuk/constants'
import getNextDate from '@mindfulchefuk/utils/getNextDate'
import { useGetDeliveries } from '@mindfulchefuk/features/Delivery/hooks/useGetDeliveries'
import { useAvailableDates } from '@mindfulchefuk/features/Operations/hooks/useAvailableDates'
import { dateFormats } from '@mindfulchefuk/lib/dateFormats'
import { useMemo } from 'react'

export enum RecipePageType {
  UNAVAILABLE = 'UNAVAILABLE',
  UNAVAILABLE_WITHIN_BUILD_TIME = 'UNAVAILABLE_WITHIN_BUILD_TIME',
  AVAILABLE = 'AVAILABLE',
}

export const useRecipePageType = (deliveryDate: string): RecipePageType => {
  const {
    recipes: { unavailableDates },
  } = useAvailableDates()

  const today = new Date()
  const deliveryDateTime = new Date(deliveryDate)
  const lastBuildDate = addDays(today, DELIVERY_EDIT_GRACE_PERIOD - 1)
  const deliveryDateIsAfterToday = isAfter(deliveryDateTime, today)
  const deliveryDateIsBeforeLastBuildDate = isBefore(
    deliveryDateTime,
    lastBuildDate
  )
  const deliveryDateIsBeforeToday = isBefore(deliveryDateTime, today)
  const deliveryDateIsToday = deliveryDate === dateFormats.iso(today)
  const deliveryDateIsLastBuildDate =
    deliveryDate === dateFormats.iso(lastBuildDate)

  const isWithinBuildTime =
    (deliveryDateIsAfterToday && deliveryDateIsBeforeLastBuildDate) ||
    deliveryDateIsLastBuildDate

  const isUnavailable =
    unavailableDates.includes(deliveryDate) ||
    deliveryDateIsBeforeToday ||
    deliveryDateIsToday

  if (isWithinBuildTime) {
    return RecipePageType.UNAVAILABLE_WITHIN_BUILD_TIME
  }
  if (isUnavailable) {
    return RecipePageType.UNAVAILABLE
  }
  return RecipePageType.AVAILABLE
}

export const useNextAvailableRecipesDate = (fromDate: string): string => {
  // get unavailable recipe dates
  const {
    recipes: { unavailableDates },
  } = useAvailableDates()

  const { data: deliveries } = useGetDeliveries()

  const datesWithDeliveries = useMemo(
    () =>
      deliveries?.reduce(
        (dates, item) =>
          !['skipped', 'cancelled'].includes(item?.status)
            ? [...dates, item.deliveryDate]
            : dates,
        []
      ) || [],
    [deliveries]
  )

  return getNextDate({
    fromDate,
    unavailableDates: [...unavailableDates, ...datesWithDeliveries],
  })
}
